button:active {
  opacity: 0.5;
}

button:focus {
  outline: 0;
}

img:active {
  opacity: 0.5;
}